
































import useAuthPermission from "@/use/authPermissions";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    companyModuleEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    companiesList: {
      type: Boolean,
      required: false,
      default: false,
    },
    companiesManagement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(_, { root }) {
    const { participantsList, participantsManagement } = useAuthPermission({
      root,
    });
    return { participantsList, participantsManagement };
  },
});
